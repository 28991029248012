import { Box, Button, Link, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';

export const CookiePopup = () => {
    const popupStyle = {
        position: 'fixed',
        bottom: '20px',
        borderRadius: "10px",
        left: '50%',
        transform: 'translateX(-50%)',
        padding: '20px',
        backgroundColor: 'white',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
        width: "90%",
        alignItems: 'center',
    };

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const cookiesAccepted = document.cookie.includes("cookiesAccepted=true");
        if (!cookiesAccepted) setIsVisible(true);
    }, []);

    const handleAcceptAll = () => {
        setIsVisible(false);
        document.cookie = "cookiesAccepted=true; path=/;";
    };

    const handleRejectAll = () => {
        setIsVisible(false);
        document.cookie = "cookiesAccepted=false; path=/;";
    };

    const handleClose = () => {
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div style={popupStyle}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <h3 style={{ textAlign: "start", margin: 0 }}>We value your privacy</h3>
                <IconButton onClick={handleClose} size="small" style={{ marginLeft: 'auto' }}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Box>
            <Box>
                <p style={{ textAlign: "start" }}>
                    We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic.
                    By clicking "Accept All," you consent to our use of cookies.
                    <Link href="/privacy-policy" target="_blank" rel="noopener noreferrer"> Cookie Policy</Link>
                </p>
            </Box>
            <Box display="flex" justifyContent="end" style={{ textAlign: "start" }}>
                <Button onClick={handleAcceptAll} sx={{ mr: 2, ml: 2, fontSize: "10px" }} variant="outlined" size="small">Accept All</Button>
                <Button onClick={handleRejectAll} variant="contained" size="small">Reject All</Button>
            </Box>
        </div>
    );
};
