import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: 1.5,
    color: theme.palette.text.primary,
    margin: "20px auto 0 !important",
    maxWidth: "90%",
    paddingBottom: "70px !important",
  },
  header: {
    margin: theme.spacing(4, 0),
    fontWeight: theme.typography.fontWeightBold,
    backgroundColor: " rgba(0, 0, 0, 0)",
    background: "linear-gradient(to right, #ff5a5a, #cb5555)",
    backgroundClip: "text",
    marginTop: 0,
    lineHeight: "44px",
    fontWeight: "700 !important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: "25px !important",
  },
  section: {
    margin: theme.spacing(2, 0),
    width: "100%",
    maxWidth: "100%",
    color: "#373737",
    textAlign: "left",
    marginTop: "20px",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "32px !important",
    display: "block",
  },
  disclaimer: {
    marginTop: "20px",
  },
}));

const TermsOfUse = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        ABOUT USING THIS WEBSITE/MOBILE APPLICATIONS:
      </Typography>
      <Box className={classes.disclaimer}>
        <Typography variant="body1" className={classes.section}>
          <Typography variant="body1" className={classes.section}>
            By using SCANBO website and mobile applications or uploading
            materials to SCANBO website, you agree to abide by the terms of use
            set forth in this notice ("Terms of Use") and privacy statement. If
            you do not agree to abide by the Terms of Use and the Privacy
            Statement, do not use a SCANBO website and mobile applications in
            any manner including but not limited to uploading or downloading any
            information or material. By using this website, you are accepting
            all the terms and conditions set forth herein. If you do not agree
            with anything in this notice you should not use this website. Your
            act of using the website and mobile applications shall be considered
            as your deed acceptance to all terms and conditions herein and no
            objection of any nature shall be treated against your acceptance.
            The product images used in this website and mobile applications are
            the intellectual property of SCANBO TECHNOLOGIES INC. ​{" "}
            <strong>You Can Contact Us For any objection at email ID: </strong>
            <a href="mailto:compliance@scanbo.com">
              <strong>compliance@scanbo.com</strong>
            </a>
            The information contained in SCANBO websites (i) is for general
            guidance on matters of interest only and shall not be assimilated to
            or substituted for legal or medical advice and (ii) do not
            constitute an offer of or solicitation for the purchase of products
            or services. &nbsp;
          </Typography>
          <br />
          <Typography variant="body1" className={classes.section}>
            <strong>CONTENT :</strong>
            <br />
            All text, graphics, user interfaces, visual interfaces, photographs,
            trademarks, logos, sounds, music, artwork and computer code
            (collectively, “Content”), including but not limited to the design,
            structure, selection, coordination, expression, “look and feel” and
            arrangement of such Content, contained on the Site is owned,
            controlled or licensed by or to SCANBO, and is protected by trade
            dress, copyright, patent and trademark laws, and various other
            intellectual property rights and unfair competition laws. You shall
            not distribute, modify, transmit, reuse, repost, or use the content
            of the website and mobile applications for public or commercial
            purposes, including the text, images, audio, or video, without the
            written permission of SCANBO. Everything you see or read on this
            website and mobile applications should be assumed to be copyrighted
            unless otherwise noted and may not be used, except as provided in
            the Terms of Use or in the text on the website, without the written
            permission of SCANBO. SCANBO neither warrants nor represents that
            your use of materials displayed on the website and mobile
            applications will not infringe rights of third parties not owned by,
            or affiliated with, SCANBO. &nbsp;Nothing contained herein shall be
            construed as conferring by implication, estoppel or otherwise any
            license or right under any patent or copyright of SCANBO, or any
            third party. &nbsp;
          </Typography>
          <br />
          <Typography variant="body1" className={classes.section}>
            <strong>WARRANTIES:</strong>
            <br />
            While every effort is made to ensure that the content of this
            website and mobile applications is accurate, the website and mobile
            applications is provided “as is” and SCANBO TECHNOLOGIES INC.
            &nbsp;makes no representations or warranties in relation to the
            accuracy or completeness of the information found on it. While the
            content of this site is provided in good faith, we do not warrant
            that the information will be kept up to date, be true and not
            misleading, or that this site will always (or ever) be available for
            use. Nothing on this website and mobile applications should be taken
            to constitute professional advice or a formal recommendation and we
            exclude all representations and warranties relating to the content
            and use of this site. To the fullest extent permissible pursuant to
            applicable law, SCANBO and its affiliates disclaim all warranties of
            any kind, whether express or implied, including without limitation
            any warranty of merchantability, fitness for a particular purpose or
            non-infringement. You expressly agree that any use of a SCANBO
            website, including all content, data or software distributed by,
            downloaded or accessed from or through a SCANBO website and mobile
            applications is at your sole risk. You understand and agree that you
            will be solely responsible for any damage to your business, your
            computer system or loss of data that results from the download of
            such content, data and/or software. Except as otherwise provided
            under applicable laws and regulations regarding the security of
            personal data, SCANBO disclaims any warranty or representation that
            confidentiality of information transmitted through a SCANBO website
            and mobile applications will be maintained. No advice or
            information, whether oral or written, obtained by you from SCANBO or
            third-parties through any SCANBO website and mobile applications
            shall create any warranty. Some states do not allow the limitation
            or exclusion of certain warranties or conditions, so some of the
            above exclusions may not apply to you.
          </Typography>
          <br />
          <Typography variant="body1" className={classes.section}>
            <strong>REFUND AND CANCELLATION: &nbsp;</strong> <br />
            No refunds will issued for any order. After 6 month of services user
            can cancel monthly subscription with 30 days prior notice to Scanbo.
          </Typography>
          <br />
          <Typography variant="body1" className={classes.section}>
            <strong>PRODUCTS INFORMATION AND DISCLAIMER:</strong> <br />
            SCANBO aims to provide a defining structure in the market through
            digitalize medium, artificial intelligence, individual ‘s biological
            conditions along with the behavioral and psychological profile.
            SCANBO Devices are portable handled devices to assist the doctor to
            get fairly accurate inputs on various parameters of individual
            including Blood pressure, Temperature, Blood Oxygen (SPO2),
            Respiratory Rate, Heart Rate, Heart Rate Variability, ECG, Blood
            Glucose, etc. This information is intended solely for the use of
            healthcare professionals. A healthcare professional must always rely
            on his or her own professional clinical judgment when deciding
            whether to use a particular product when treating a particular
            patient. SCANBO does not dispense medical advice and recommends that
            healthcare professionals be trained in the use of any particular
            product before using it in a procedure/Treatment/Tests etc. A
            healthcare professional must always refer to the package insert,
            product label and/or instructions for use, including the
            instructions for cleaning and sterilization (if applicable), before
            using any SCANBO product. The information presented is intended to
            demonstrate particular products, as well as the eadth of SCANBO
            product offerings. Products may not be available in all markets
            because product availability is subject to the regulatory and/or
            medical practices in individual markets. Please contact your SCANBO
            representative if you have questions about the availability of
            specific SCANBO products in your area. &nbsp;We make every effort to
            provide you the most accurate, up-to-date information; however, it
            is your responsibility to verify with this company that all details
            listed are accurate.
          </Typography>
          &nbsp;
          <br />
          <Typography variant="body1" className={classes.section}>
            <strong>LIMITED RIGHT TO USE DATA; USE OF COOKIES</strong> <br />
            User grants us the right to use, copy, store, transmit and display
            User data solely to the extent necessary to provide the Platform and
            associated maintenance, for purposes of ensuring security, and for
            the investigation of complaints. &nbsp;All data submitted by User to
            the Platform (User Data) will remain the sole property of User.
            &nbsp;Subject to the terms of this Agreement, we shall not disclose
            User Data or use User data for any purpose other than to provide the
            Platform and as set forth herein. &nbsp;The Platform uses "cookies",
            which are small text files placed on the User’s hard disk by a Web
            server. &nbsp;Cookies contain information that can later be read by
            a Web server in the domain that issued the cookie to the User.
            &nbsp;One of the primary purposes of cookies is to store the User’s
            preferences and other information on the User’s computer in order to
            save the User’s time by eliminating the need to repeatedly enter the
            same information and to display the User’s personalized content and
            targeted advertising on the User’s later visits to this Platform.
            &nbsp;When the User signs in the Platform named User account, we
            store the User’s unique ID number, and the time the User signed in,
            in an encrypted cookie on the User’s hard disk. &nbsp;This cookie
            allows the User to move from page to page at the Platform without
            having to sign in again on each page. When the User signs out, these
            cookies are deleted from the User’s computer. &nbsp;We also use
            cookies to improve the sign-in experience. User agrees that we may
            use Usage Statistics to provide Users with communications regarding
            Platform support, news, and information regarding the Platform and
            services/products provided by us, except where such Users opt out of
            receiving such information.{" "}
          </Typography>
          <br />
          <Typography variant="body1" className={classes.section}>
            <strong>LICENSE TO USE THIS PLATFORM:</strong>
            Subject to the terms and ccondition,we hereby grant to User a
            non-exclusive and non-transferable worldwide right to use the
            Platform, solely for User’s personal purposes. &nbsp;Where the
            Subscribing Organization is a medical practitioner or using ‘medical
            devices /equipment for pre-approved business purposes’ and shall
            include acting for the benefit of such firm’s third-party clients.
            &nbsp;All rights not expressly granted to User are reserved by us.
            Each User’s first name and last name may be publicly displayed on
            their Platform account. It is the User’s responsibility : To
            maintain the confidentiality of any password associated with their
            use of the Platform. &nbsp;User agrees that we may send User
            information and notices regarding the Platform, User’s Platform
            registration or account, by email, text messaging, Platform Private
            Message or other means based on the information User provides to us.
            User shall report all attempts of unauthorized access to the
            Platform or the Content, of which they become aware. &nbsp;User
            shall cooperate with us in our investigation and remediation of any
            security, unauthorized use or misuse issues arising from or related
            to User’s use of the Platform &nbsp;If any of the points in this
            disclaimer notice are found to be unenforceable under applicable law
            that will have no bearing on the enforceability of the rest of the
            disclaimer notice.{" "}
          </Typography>
          <br />
          <Typography variant="body1" className={classes.section}>
            <strong>PROHIBITED ACTIVITIES/ USER’S REPRESENTATIONS:</strong>{" "}
            <br />
            As a user of the Platform, you agree not to:Systematically retrieve
            data or other content from the Platform to create or compile,
            directly or indirectly, a collection, compilation, database, or
            directory without written permission from us;Make any unauthorized
            use of the Platform, including collecting usernames and/or email
            addresses of users by electronic or other means for the purpose of
            sending unsolicited emails, or creating user accounts by automated
            means or under false pretenses;Use a buying agent or purchasing
            agent to make purchases on the Platform;Use the Platform to
            advertise or offer to sell goods and services;Circumvent, disable,
            or otherwise interfere with security-related features of the
            Platform, including features that prevent or restrict the use or
            copying of any Content or enforce limitations on the use of the
            Platform and/or the Content contained therein;Engage in unauthorized
            framing of or linking to the Platform;Trick, defraud, or mislead us
            and other users, especially in any attempt to learn sensitive
            account information such as user passwords;Make improper use of our
            support services or submit false reports of abuse or
            misconduct;Engage in any automated use of the system, such as using
            scripts to send comments or messages, or using any data mining,
            robots, or similar data gathering and extraction tools;Interfere
            with, disrupt, or create an undue burden on the Platform or the
            networks or services connected to the Platform;Attempt to
            impersonate another user or person or use the username of another
            user;Sell or otherwise transfer your profile;Use any information
            obtained from the Platform in order to harass, abuse, or harm
            another person;Use the Platform as part of any effort to compete
            with us;Decipher, decompile, disassemble, or reverse engineer any of
            the software comprising or in any way making up a part of the
            Platform;Attempt to bypass any measures of the Platform designed to
            prevent or restrict access to the Platform, or any portion of the
            Platform;Harass, annoy, intimidate, or threaten any of our employees
            or agents engaged in providing any portion of the Platform to
            you;Delete the copyright or other proprietary rights notice from any
            Content;Copy or adapt the Platform’s software, including but not
            limited to Flash, PHP, HTML, JavaScript, or other code;Upload or
            transmit (or attempt to upload or to transmit) viruses, Trojan
            horses, or other such material;Upload or transmit (or attempt to
            upload or to transmit) any material that acts as a passive or active
            information collection or transmission mechanism, including without
            limitation, clear graphics interchange formats (“gifs”), 1×1-pixel,
            web bugs, cookies, or other similar devices (sometimes referred to
            as “spyware” or “passive collection mechanisms” or “pcms”);Except as
            may be the result of a standard search engine or Internet browser
            usage, use, launch, develop, or distribute any automated system,
            including without limitation, any spider, robot, cheat utility,
            scraper, or offline reader that accesses the Platform, or using or
            launching any unauthorized script or other software;Disparage,
            tarnish, or otherwise harm, in our opinion, us and/or the
            Platform;Use the Platform in a manner inconsistent with any
            applicable laws or regulations;License, sublicense, sell, resell,
            transfer, assign, distribute or otherwise make the Platform or the
            Technology available to any unauthorized third party;Copy, modify,
            make derivative works based upon the Technology or the Content,
            distribute any portion of the Content other than to the extent that
            they are expressly licensed to do so in this Agreement or as
            otherwise expressly required by law;Use, analyze, reverse engineer
            or apply any procedure or process of the Technology or the Content
            in order to ascertain, derive, and/or appropriate, for any reason or
            purpose, Content or any information regarding the linkages, layouts
            or proprietary processes of Content;Allow any unauthorized person to
            access the Platforms or Content (whether on a User’s behalf or
            otherwise) or disclose User’s password to any third party, except as
            expressly permitted under this Agreement; Create derivative
            databases or other works using all or some of the Content, or
            otherwise using the Platform or any portion or derivation of the
            Content in any other product or service;Use “web crawlers” or other
            types of software or hardware technology to automatically download
            or index Content from the Platform;Use the Platform to store,
            distribute or transmit any unlawful, harmful, threatening,
            defamatory, obscene or harassing material or information, including,
            but not limited to any computer virus or other harmful
            software;Create a denial of service, make unauthorized modifications
            of or otherwise impede the Platform or use the Platform in any
            manner that would infringe upon our intellectual property rights or
            those of our third-party suppliers of Content. We reserve the right
            to modify or amend the list of prohibited activities without any
            prior notice to the user.{" "}
          </Typography>
          <br />
          <Typography variant="body1" className={classes.section}>
            <strong>INTELLECTUAL PROPERTY RIGHTS :</strong> <br />
            The contents of SCANBO websites are protected by worldwide copyright
            and trademark laws. Any rights not expressly granted herein are
            reserved. Reproduction, transfer, distribution or storage of part or
            all of the contents in any form without the prior written permission
            of SCABO is prohibited except in accordance with the following
            terms. SCANBO consents to you browsing SCANBO’S websites on your
            computer and printing or downloading content only for your personal
            use for non-commercial purposes provided that such uses are not
            competitive with or derogatory to SCANBO, but no modification or
            further reproduction of the content is permitted. The content may
            otherwise not be copied or used in any way without the prior written
            consent from an authorized SCANBO representative. Individual
            documents on a SCANBO’S website and mobile applications may be
            subject to additional terms indicated in those documents. The
            trademarks, service marks, trade names, trade dress and products
            contained in SCANBO’S websites are protected in the United States
            and internationally. SCANBO is a registered trademark of SCANBO
            Corporation. Other product and company names mentioned in SCANBO’S
            websites may be trademarks or trade names of their respective
            owners. Your access to SCANBO ’S websites should not be construed as
            granting, by implication, estoppel or otherwise, any license or
            right to use any marks appearing on SCANBO ’S websites without the
            prior written consent of an authorized SCANBO representative or the
            third-party owner thereof except to identify the products or
            services of the company. These were governed by the Intellectual
            property rights and unfair competition laws of India, foreign
            jurisdictions, and international conventions as applicable from time
            to time.If you believe that your intellectual property has been
            improperly used on a SCANBO’S website and mobile applications please
            contact SCANBO’S &nbsp;Compliance Department at the address provided
            below: &nbsp;
          </Typography>
          <br />
          <Typography variant="body1" className={classes.section}>
            <strong>
              The Compliance Department,
              <br />
              SCANBO TECHNOLOGIES INC. Correspondence Address:{" "}
            </strong>
            308 – 221 West Esplanade North Vancouver, BC &nbsp;V7M 3J3
          </Typography>
          <br />
          <Typography variant="body1" className={classes.section}>
            <strong>LINKS TO OTHER WEBSITES :</strong> <br />
            Our Service may contain links to third-party websites or services
            that are not owned or controlled by us. We have no control over and
            assume no responsibility for, the content, privacy policies, or
            practices of any third-party websites or services. You further
            acknowledge and agree that we shall not be responsible or liable,
            directly or indirectly, for any damage or loss caused or alleged to
            be caused by or in connection with the use of or reliance on any
            such content, goods or services available on or through any such
            websites or services.All links to a SCANBO website and mobile
            applications must be approved in writing by SCANBO, except that
            SCANBO consents to links in which: (a) the link is a text-only link
            containing only the title of the home page of SCANBO’S website, (b)
            the link "points" only to the home page of SCANBO’S website and
            mobile applications and not to deeper pages, (c) the link, when
            activated by a user, displays this home page of SCANBO’S website and
            mobile applications in full-screen and not within a "frame" on the
            linked website, and (d) the appearance, position and other aspects
            of the link do not (i) create the false appearance that an entity or
            its activities or products are associated with or sponsored by
            SCANBO or its affiliates, or (ii) damage or dilute the goodwill
            associated with the name and trademarks of SCANBO or its affiliates.
            In any event, you agree that SCANBO reserves the right to withdraw
            this consent to link without reason or notice and at any time and
            you further undertake to delete any link for which SCANBO’S consent
            has been withdrawn.
          </Typography>
          <br />
          <Typography variant="body1" className={classes.section}>
            <strong>LIMITATION OF LIABILITY:</strong> <br />
            To the extent permitted by law, under no circumstances, including,
            without limitation, negligence, shall SCANBO or its parents,
            subsidiaries, affiliates, officers, directors, employees, agents or
            suppliers be liable for any direct, indirect, incidental, special or
            consequential damages arising from or in connection with the use of
            or the inability to use a SCANBO website and mobile applications or
            any content contained on a SCANBO website, or, except as otherwise
            provided under applicable laws and regulations regarding the
            security of personal data, resulting from unauthorized access to or
            alteration of your transmissions or data, or other information that
            is sent or received or not sent or received, including but not
            limited to, damages for loss of profits, use, data or other
            intangibles, even if SCANBO has been advised of the possibility of
            such damages.The parties acknowledge that this is a reasonable
            allocation of risk, even if SCANBO has been advised of the
            possibility of such damages. Some jurisdictions do not allow the
            exclusion of certain warranties or limitations of liability, so the
            above limitations or exclusions may not apply to you.We will not be
            liable to any user for any loss or damage, whether in contract, tort
            (including negligence), each of statutory duty, or otherwise, even
            if foreseeable, arising under or in connection with: 1. Use of, or
            inability to use, the Platform; 2. Delays or disruptions to the
            availability of the Platform; 3. The content, actions, or inactions
            of third parties (including the IP Holders), including documents
            uploaded to the Platform; or 4. Use of or reliance on any content
            accessed through the Platform. 5. any loss or damage caused by a
            virus, distributed denial-of-service attack, glitches, bugs or other
            technologically harmful material that may infect or damage your
            computer equipment, computer programs, data or other proprietary
            material due to your use of the Platform or to your downloading of
            any content accessible through the Platform. Subject to the
            foregoing, our total aggregate liability, whether in contract, tort
            (including negligence and each of statutory duty, howsoever
            arising), misrepresentation (whether negligent or innocent),
            restitution or otherwise, arising under or in connection with these
            terms of use shall be limited to the Filing Charges that the IP
            Holder paid to us in respect of transactions with you in the
            relevant month.The IP Holder and Filing Party shall not commence
            proceedings or prosecute any action, seek, obtain, or enforce a
            judgment, or be entitled to obtain specific performance or any other
            remedy or relief against us, relating to any claims for any of the
            obligations contained in the existing contract between the IP Holder
            and Filing Party.The liability of SCANBO would in such case be
            limited to the greatest extent permitted by law.{" "}
          </Typography>
          <br />
          <Typography variant="body1" className={classes.section}>
            <strong>GUIDELINES FOR REVIEWS AND COMMENTS :</strong>
            <br />
            when posting a review, you must comply with the following
            criteria:(1) you should have the first-hand experience with the
            person/entity being reviewed;(2) your reviews should not contain
            offensive profanity, or abusive, racist, offensive, or hate
            language;(3) your reviews should not contain discriminatory
            references based on religion, race, gender, national origin, age,
            marital status, sexual orientation, or disability;(4) your reviews
            should not contain references to illegal activity;(5) you should not
            be affiliated with competitors if posting negative reviews;(6) you
            should not make any conclusions as to the legality of conduct;(7)
            you may not post any false or misleading statements; and(8) you may
            not organize a campaign encouraging others to post reviews, whether
            positive or negative. we may accept, reject, or remove reviews at
            our sole discretion. we have absolutely no obligation to screen
            reviews or to delete reviews, even if anyone considers reviews
            objectionable or inaccurate. &nbsp;reviews are not endorsed by us,
            and do not necessarily represent our opinions or the views of any of
            our affiliates or partners. &nbsp;we do not assume liability for any
            review or for any claims, liabilities, or losses resulting from any
            review. by posting a review, you hereby grant to us a perpetual,
            non-exclusive, worldwide, royalty-free, fully-paid, assignable, and
            sublicensable right and license to reproduce, modify, translate,
            transmit by any means, display, perform, and/or distribute all
            content relating to reviews.
          </Typography>
          <br />
          <Typography variant="body1" className={classes.section}>
            <strong>INDEMNIFICATION:</strong> <br />
            You agree to defend, indemnify, and hold us harmless, including our
            subsidiaries, affiliates, and all of our respective officers,
            agents, partners, and employees, from and against any loss, damage,
            liability, claim, or demand, including reasonable attorneys’ fees
            and expenses, made by any third party due to or arising out of:(1)
            your contributions (uploads, reviews, illegal activities);(2) use of
            the platform;(3) each of these terms;(4) your violation of the
            rights of a third party, including but not limited to intellectual
            property rights; or(5) any overt harmful act toward any other user
            of the platform with whom you connected via the platform.In no event
            will SCANBO be liable for any incidental, indirect, consequential or
            special damages of any kind, or any damages whatsoever, including,
            without limitation, those resulting from loss of profit, loss of
            contracts, goodwill, data, information, income, anticipated savings
            or business relationships, whether or not advised of the possibility
            of such damage, arising out of or in connection with the use of this
            website and mobile applications or any linked
            websites.Notwithstanding the foregoing, we reserve the right, at
            your expense, to assume the exclusive defense and control of any
            matter for which you are required to indemnify us, and you agree to
            cooperate, at your expense, with our defense of such claims. We will
            use reasonable efforts to notify you of any such claim, action, or
            proceeding which is subject to this indemnification upon becoming
            aware of it.
          </Typography>
          <br />
          <Typography variant="body1" className={classes.section}>
            <strong>DISCLOSURE POLICY:</strong>
            <br />
            SCANBO may disclose any information we have about you (including
            your identity) if we determine that such disclosure is necessary for
            connection with any investigation or complaint regarding your use of
            the Site, or to identify, contact or ing legal action against
            someone who may be causing injury to or interference with (either
            intentionally or unintentionally) SCANBO’S rights or property, or
            the rights or property of visitors to or users including SCANBO’S
            customers. We reserve the right at all times to disclose any
            information that deems necessary to comply with any applicable law,
            regulation, legal process or governmental request. The website and
            mobile applications don't accept any forms of cash advertising,
            sponsorship, paid insertions or other forms of compensation. The
            Company reserves the right to change the Disclaimer/Privacy Policy
            under which this website and mobile applications are offered at any
            time and without notice. You will be automatically bound by these
            modifications when you use this site, and should periodically read
            the Disclaimer &amp; Privacy Policy. After having read and
            understood the disclaimer and privacy notice, I hereby agree to
            accept and abide by all the terms and conditions as mentioned
            therein at my sole responsibility.
          </Typography>
          <br />
          <Typography variant="body1" className={classes.section}>
            &nbsp;<strong>APPLICABLE LAW - SEVERABILITY:</strong>
            <br />
            These Terms of Use are governed by the Canada Law. The courts in
            Canada have jurisdiction in relation to any claim or action arising
            out of, or in connection with, the Terms of Use.If any provision of
            these Terms of Use is held by a court to be illegal, invalid or
            unenforceable, the remaining provisions shall remain in full force
            and effect.Thus, specifically mentioned as follows:These terms of
            use and any dispute or claim arising out of or in connection with
            them or their subject matter or formation (including non-contractual
            disputes or claims) shall be governed by and construed in accordance
            with the law of Canada and you and we irrevocably agree that the
            courts of Canada shall have exclusive jurisdiction to settle any
            dispute or claim arising out of or in connection with these terms of
            use or their subject matter or formation (including non-contractual
            disputes or claims). By using this Platform or availing its
            services, you consent to the jurisdiction and venue of such courts
            in connection with any action, suit, proceeding or claim aristo
            arise or by reason of this Agreement.STATUTE OF LIMITATION: You
            agree that regardless of any statute or law to the contrary, any
            claim or cause of action arising out of or related to use of the
            Platform or its services or this Agreement must be filed within one
            (1) year after such claim or cause of action arose or be forever
            barred.WAIVER OF CLASS ACTION RIGHTS: By entering into this
            agreement, you hereby irrevocably waive any right you may have to
            join claims with those of other in the form of a class action or
            similar procedural device. any claims arising out of, relating to,
            or connection with this agreement must be asserted individually.{" "}
          </Typography>
          <br />
          <Typography variant="body1" className={classes.section}>
            <strong>MODIFICATIONS OF THE TERMS OF USE:</strong> <br />
            The Company reserves the right to change the Terms of Use under
            which this website and mobile applications is offered at any time
            and without notice. You will be automatically bound by these
            modifications when you use this site, and should periodically read
            the Terms of Use.There may be information on the Platform that
            contains typographical errors, inaccuracies, or omissions that may
            relate to the Platform, including descriptions, pricing,
            availability, and various other information. &nbsp;We reserve the
            right to correct any errors, inaccuracies, or omissions and to
            change or update the information on the Platform at any time,
            without prior notice. We reserve the right at any time to:change the
            terms and conditions of this Agreement;change the Platform,
            including eliminating or discontinuing any content or feature of the
            Platform; orchange any fees or charges associated with any services
            available through the Platform.We may modify this Agreement from
            time to time without notice to you.You agree to review this
            Agreement periodically to ensure that you are aware of any
            modifications. Your continued access or use of the Platform shall
            constitute your agreement to be bound by the modified terms and
            conditions of this Agreement. You will be deemed to have been made
            aware of and to have accepted, the above stated changes by your
            continued use of the Platform after the date such revised Terms are
            posted. After having read and understood the disclaimer and
            copyright notice, I hereby agree to accept and abide by all the
            terms and conditions as mentioned therein at my sole responsibility.
          </Typography>
          <br />‍
          <strong>
            CONTACT SCANBO: If you have any questions about these Terms of Use,
            please write to:
            <br />
            SCANBO TECHNOLOGIES INC
            <br />
            Correspondence Address:{" "}
          </strong>
          308 – 221 West Esplanade North Vancouver, BC &nbsp;V7M 3J3
          <br />‍
          <strong>
            PHONE NO. + 1 672 513 0044
            <br />
            MAIL ID :{" "}
          </strong>
          <a href="mailto:compliance@scanbo.com">
            <strong>compliance@scanbo.com</strong>
          </a>
        </Typography>
        {/* Repeat for each section of the disclaimer */}
      </Box>
      {/* Additional content if needed */}
    </div>
  );
};

export default TermsOfUse;
